body {
  margin: 0;
  font-family: 'Syne', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 95vw;
  min-height: 100vh;
  background-color: maroon;
  overflow-x: hidden;
}